import "./src/styles/global.css"
import "./src/assets/fonts/iconfont/icons.css"
import "@brainhubeu/react-carousel/lib/style.css"
import Cookies from "universal-cookie"

export const disableCorePrefetching = () => true

export const onRouteUpdate = ({ location, prevLocation }) => {
  const cookies = new Cookies()
  const allowedTracking = cookies.get("gatsby-gdpr-google-tagmanager")
  if (
    typeof window !== "undefined" &&
    // @ts-ignore
    typeof window.dataLayer !== "undefined" &&
    allowedTracking === "true"
  ) {
    if (!prevLocation) {
      window.addEventListener(
        "CookiebotOnAccept",
        function (e) {
          if (window.Cookiebot.consent.statistics) {
            window.dataLayer.push({
              event: "Pageview",
              pagePath: location.pathname,
            })
          }
        },
        false
      )
    } else {
      window.removeEventListener("CookiebotOnAccept", () => {})
      window.dataLayer.push({
        event: "Pageview",
        pagePath: location.pathname,
      })
    }
  }
}
